footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625em;
	}
}

//Footer Top Rows
.footer-top {
	background-color: $brandcolor1;
	padding: 2.5rem 1.25rem;
	.footer-top-inner {
		.footer-logo-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;
			.footer-logo {
				display: block;
				width: 250px;
				height: 89px;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0;
				// @media (max-width: 991.9px) {
				// 	width: 225px;
				// 	height: 43px;
				// }
				// @media (max-width: 767.9px) {
				// 	width: 300px;
				// 	height: 57px;
				// }
			}
		}
		.footer-affiliations-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			.footer-affiliations {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 1.25rem;
				.footer-affiliation {
					.footer-affiliation-logo {
						max-height: 5.0rem;
						max-width: 4.5rem;
					}
				}
			}
		}
		.footer-info-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			color: $white;
			font-size: 1.125rem;
			letter-spacing: 0.05em;
			line-height: 1;
			text-transform: uppercase;
			gap: 0.625em;
			flex-wrap: nowrap;
			@media (max-width: 991.9px) {
				justify-content: center;
				flex-wrap: wrap;
				padding: 1.25em 0.625em;
			}
			@media (max-width: 575.9px) {
				gap: 0.625em;
			}
			b, strong {
				color: $brandcolor2;
			}
			a {
				color: $white;
				&:hover {
					color: $gray-white2;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					text-decoration: none;
				}
			}
			.info-address {
				// white-space: nowrap;
			}
			.info-phone {
				// white-space: nowrap;
				a {
					white-space: nowrap;
				}
			}
			.footer-links {
				ul.list-inline {
					margin: 0;
					padding: 0;
					// border-left: 2px solid $brandcolor2;
					li.list-inline-item {
						margin: 0 0 -0.0625rem 0;
						padding: 0 0.625rem;
						// border-right: 1px solid $white;
						&:first-child {
						}
						&:last-child { 
							border-right: none; 
						}
						a {
							margin: 0;
							padding: 0.625rem;
							font-size: 0.75rem;
							line-height: 1;
							color: $white;
							border: 1px solid $brandcolor2;
							white-space: nowrap;
							// border-bottom: 0.125rem solid rgba($white, 0);
							-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
							-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
							-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
							-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
							transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
							&:hover {
								background-color: $brandcolor2;
								color: $white;
								text-decoration: none;
								// border-bottom: 0.125rem solid $brandcolor2;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background-color: $brandcolor2-dark;
								color: $white !important;
								text-decoration: none;
								border: 1px solid $brandcolor2-dark;
								// border-bottom: 0.125rem solid $brandcolor2-dark;
							}
						}
					}
				}
				@media (max-width: 991.9px) {
					padding: 0.625em 2.5em;
				}
				@media (max-width: 575.9px) {
					ul {
						list-style-type: none;
						margin: 0 0 2.5em 0;
						padding: 0;
						li.list-inline-item {
							display: block;
							margin: 0;
							padding: 0.625em 0;
							line-height: 1;
							border-right: none;
							border-bottom: 1px solid $gray-light1;
							&:first-child { 
								// padding-top: 0; 
							}
							&:last-child { 
								border-bottom: none; 
							}
							&::marker {
								content: "";
								display: none;
							}
							b {
								color: $black;
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}

// Footer Bottom Rows
.footer-bottom {
	background-color: $brandcolor1-dark;
	position: relative;
	z-index: 1;
	.footer-bottom-inner {
		padding: 2.5em 1.875em 5em 1.875em;
		text-align: center;
		.footer-copyright {
			color: $white;
			font-size: 0.75em;
			line-height: 1;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
		}
		.footer-social-icon {
			color: $gray-mid2;
			line-height: 1;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
			&:hover {
				color: $white;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $white;
				text-decoration: none;
			}
		}
		.footer-social-desc {
			display: none;
		}
	}
}
