.block-banner-outer-container {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

// Swiper JS Classes
.banner-slide {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	z-index: 1;
}
.banner-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}





// Bootstrap 4.6 Classes
.carousel {
	width: 100%;
	height: auto;
	position: relative;
	z-index: 1;
}

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	// height: calc(100vw * 0.5625);
	position: relative;
	z-index: 1;
}

.carousel-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}

.carousel-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: rgba($gray-dark3, 0.33);
}

.banner-title-overlay {
	position: absolute;
	left: 0;
	bottom: -0.25em;
	width: 100%;
	max-width: 1800px;
	padding: 0;
	margin: 0;
	z-index: 3;
	a.banner-title-link {
		background-color: $white;
		color: $gray-mid2;
		font-size: 0.6em;
		line-height: 1;
		margin: 0 0 0 10%;
		padding: 0.75em 2.0em;
		-webkit-transition: color 1.0s ease-out;
		-moz-transition: color 1.0s ease-out;
		-ms-transition: color 1.0s ease-out;
		-o-transition: color 1.0s ease-out;
		transition: color 1.0s ease-out;
		&:hover {
			color: $brandcolor1;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
			text-decoration: none;
		}
		.fa, .fac {
			color: $brandcolor1;
			font-size: 1.0em;
			// transform: translateY(-0.00625em);
		}
	}
}

.container-caption {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	.container-fluid {
		display: flex;
		justify-content: stretch;
		align-items: center;
	}
}

.carousel-caption {
	position: static;
	display: block;
	width: 50% !important;	
	margin: 0;
	padding: 0 1.25em;
	-webkit-transition: transform 1.0s ease-out;
	-moz-transition: transform 1.0s ease-out;
	-ms-transition: transform 1.0s ease-out;
	-o-transition: transform 1.0s ease-out;
	transition: transform 1.0s ease-out;
	@media (max-width: 991.9px) {
		width: 66.66% !important;
	}
	@media (max-width: 767.9px) {
		width: 83.33% !important;
	}
	@media (max-width: 575.9px) {
		width: 100% !important;
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0;
		h1 {
			color: $white;
			margin: 0;
			padding: 0;
			font-size: calc(3em + 1 * ((100vw - 36em) / 39));
			font-size: -webkit-calc(3em + 1 * ((100vw - 36em) / 39));
			font-size: -moz-calc(3em + 1 * ((100vw - 36em) / 39));
			border-left: 0.625rem solid $brandcolor2;
			margin: 0.625rem 0 2.5rem 0;
			padding: 0 0 0 1.875rem;
			sup {
				display: inline-block;
				padding-left: 0.25em;
				font-size: 0.25em;
				transform: translateY(-1.625em);
			}
			@media (max-width: 575.9px) {
				font-size: 3em;
			}
			@media (min-width: 1200px) {
				font-size: 4em;
			}
		}
		hr.caption-divider {
			overflow: visible; /* For IE */
			padding: 0;
			margin: 0 0 0.625em 0;
			border: none;
			border-top: 1px solid $brandcolor1;
			color: $white;
			text-align: right;
		}
		.caption-body {
			padding-top: 0;
			p {
				line-height: 1;
			}
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
  // border-radius: 50%;
  &:hover {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	display: inline-block;
	content: "\f001";
	font-family: $font-icons2;
	font-size: 2.625em; 
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	color: $white;
}

.carousel-control-next-icon::after {
	content: "\f000";
	font-family: $font-icons2;
	font-size: 2.625em;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	color: $white;
}

.carousel-indicators {
	left: 0;
	justify-content: center;
	margin: 0.625em 0;
	z-index: 2;
}

.carousel-indicators li {
	width: 0.625em;
	height: 0.625em;
	border-radius: 50%;
	background: none;
	border: 0.125em solid $white;
	opacity: 1.0;
	margin: 0 0.1em !important;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-o-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	transition: background-color 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		background-color: $white;
		border: 0.3125em solid $white;
	}
	&.active {
		background-color: $brandcolor2;
		border: 0.125em solid $brandcolor2;
	}
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	@supports (height:100dvh) {
		height: 100dvh;
	}
	z-index: 1;
	text-align: center;
	pointer-events: none;
}

#banner-down-arrow a {
	position: absolute;
	bottom: 4.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 3.125em;
		color: $white;
		&:hover {
			color: $brandcolor2;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor2-dark;
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}