/* Pagination Classes */

.pagination-container {
	padding: 1.25em 0.625em 5.0em 0.625em;
	.pagination {
		font-size: 1.0rem;
		font-weight: 400;
		background: none;
		border-radius: 0;
		@media (max-width: 991.9px) {
			font-size: 1.125rem;
		}
		@media (max-width: 767.9px) {
			font-size: 1.25rem;
		}
		a {
			color: $brandcolor1;
			background: none;
			padding: 0.625em 1.25em;
			border: none;
			&:hover {
				color: $brandcolor1-light;
				background: none;
			}
			.fa, .fas, .fac {
				font-size: 0.8em;
				transform: translateY(0em);
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: none;
			}
		}
		.active {
			a {
				padding: 0.625em 1.25em;
				background: none !important;
				color: $brandcolor2 !important;
				font-weight: 700;
				pointer-events: none;
				cursor: auto;
			}
		}
		.page-item:not(:last-child) {
			margin: 0;
			border-right: 1px solid $gray-light1;
		}
		.page-item + .page-item {
			margin: 0;
			margin-left: -1px;
			@media (max-width: 767.9px) {
				display: none;
				&.active {
					display: block;
				}
			}
		}
		.page-item:first-child, .page-item:last-child {
			font-weight: 700;
			@media (max-width: 767.9px) {
				display: block;
			}
		}

		.page-item.disabled { 
			.page-link {
				color: $gray-light1 !important;
				background: none;
				pointer-events: none;
				cursor: auto;
			}
		}
	}
}