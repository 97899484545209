/* Main Projects Page */
.project-cards-outer-container {
	margin: 0;
	padding-top: 2.5rem;
	padding-bottom: 5.0rem;
	.project-card-container {
		padding: 0 2.5rem;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
}

// Project Teaser Cards
.project-card {
	// width: calc(100% - 1.25em);
	margin: 0 0 3.75rem 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.project-teaser-headline h3 {
				color: $brandcolor2;
				text-decoration: none;
				.fa, .fac {
					color: $brandcolor2;
				}
				&::after {
					color: $brandcolor2;
				}
			}
		}
		.card-footer a {
			color: $brandcolor1-light;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-light;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.project-teaser-headline h3 {
				color: $brandcolor1-dark;
				text-decoration: none;
				.fa, .fac {
					color: $brandcolor1-dark;
				}
			}
		}
		.card-footer a {
			color: $brandcolor1-dark;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-dark;
			}
		}
	}
	.card-body {
		padding: 1.25em 0 0 0;
		.project-teaser-headline h3 {
			margin: 0 1.5rem 0.3125rem 0;
			color: $black;
			font-weight: 700;
			letter-spacing: -.025em;
			-webkit-transition: color 1.0s ease-out;
			-moz-transition: color 1.0s ease-out;
			-ms-transition: color 1.0s ease-out;
			-o-transition: color 1.0s ease-out;
			transition: color 1.0s ease-out;
			&::after {
				content: "\f008";
				font-family: $font-icons2;
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0;
				color: $gray-mid2;
			}
		}
		.project-teaser-copy {
			color: $gray-mid2;
			font-size: 1.0rem;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 0;
	}
}

/* Project Detail Page */
.project-overview-container {
	margin: 0;
	padding: 5.0rem 2.5rem 5.0rem 2.5rem;
	.project-overview-body-container {
		.project-overview-body-headline-container {
			margin-bottom: 2.5rem;
		}
	}
	.project-overview-lists-container {
		column-count: 2;
		column-gap: 2.5em;
		.project-overview-single-list-container {
			break-inside: avoid-column;
			// margin-bottom: 0.625rem;
			.project-overview-list-header {
				font-size: 0.9375rem;
				font-weight: 600;
				line-height: 1;
				letter-spacing: 0.05rem;
				color: $brandcolor2;
				text-transform: uppercase;
			}
		}
		@media (max-width: 991.98px) {
			column-count: 1;
		}
		@media (max-width: 575.98px) {
			column-count: 2;
			padding-top: 1.25rem;
		}
		@media (max-width: 459.98px) {
			column-count: 1;
		}
	}
}

.header-image-slider-full-width {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $brandcolor1;
	.image-slider {
		&.loading {
			//min-height: 60vh;
		}
		.image-slider-wrapper {
			.image-slide {
				.image-slide-image {
					//max-height: 60vh;
				}
			}
		}
	}
}
