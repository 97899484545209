/* Safari fixes */
.swiper-wrapper{
	-webkit-transform-style: preserve-3d;
}
.swiper-slide {
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
}

.image-slider {
	min-height: auto;
	&.loading {
		//min-height: 66vh;
	}
	// width: 100vw;
	width: 100%;
	max-width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	//** height: calc(100vh - 10em);
	.image-slider-wrapper {
		.image-slide {
			display: inline-block;
			width: auto;
			// height: 100vh; // Some browsers, including Safari need a height in this context
			// @supports (height:100dvh) {
			// 	height: 100dvh;
			// }
			//max-width: 100vw;
			//max-height: calc(100vh - 10em);
			overflow: hidden;
			.image-slide-image {
				// width: auto;
				// height: 100%;
				max-width: 100vw;
				max-height: 100vh;
				@supports (height:100dvh) {
					max-height: 100dvh;
				}
				opacity: 0.75;
				border: none;
				-webkit-filter: blur(0px) grayscale(0%) contrast(50%);
				-moz-filter: blur(0px) grayscale(0%) contrast(50%);
				-ms-filter: blur(0px) grayscale(0%) contrast(50%);
				-o-bfilter: blur(0px) grayscale(0%) contrast(50%);
				filter: blur(0px) grayscale(0%) contrast(50%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
			}
			figure {
				figcaption {
					margin: 0;
					padding: 0.625rem 0 2.25rem 0;
				}
			}
		}
		.swiper-slide-active {
			.image-slide-image {
				opacity: 1.0;
				border: none;
				max-width: 100vw;
				min-height: 33vh;
				-webkit-filter: blur(0) grayscale(0) contrast(100%);
				-moz-filter: blur(0) grayscale(0) contrast(100%);
				-ms-filter: blur(0) grayscale(0) contrast(100%);
				-o-bfilter: blur(0) grayscale(0) contrast(100%);
				filter: blur(0) grayscale(0) contrast(100%);
			}
		}
	}
}

.image-slider-video-thumb-container {
	position: relative;
	width: auto;
	&:hover {
		.video-thumb-overlay {
			background-color: rgba($black, 0.5);
			.video-thumb-button-container {
				background-color: rgba($black, 0.5);
				.video-thumb-button {
					color: $white;
					.link-text-nav {
						color: $white;
					}
				}
			}
		}
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		background-color: rgba($gray-mid2, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button-container {
			display: block;
			margin: 0;
			padding: 0.625rem;
			background-color: rgba($brandcolor1, 0.90);
			-webkit-transition: all 2.0s ease-out;
			-moz-transition: all 2.0s ease-out;
			-ms-transition: all 2.0s ease-out;
			-o-transition: all 2.0s ease-out;
			transition: all 2.0s ease-out;
			.video-thumb-button {
				color: $brandcolor2;
				text-align: center;
				-webkit-transition: all 2.0s ease-out;
				-moz-transition: all 2.0s ease-out;
				-ms-transition: all 2.0s ease-out;
				-o-transition: all 2.0s ease-out;
				transition: all 2.0s ease-out;
				a {
					color: $brandcolor1-light;
					text-decoration: underline;
					&:hover {
						color: $brandcolor1-light2;
						text-decoration: underline;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1;
						text-decoration: underline;
					}
				}
				.link-text-nav {
					display: block;
					color: $white;
					// font-size: 0.8125em;
					font-size: calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					font-size: -webkit-calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					font-size: -moz-calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					@media (max-width: 575.9px) {
						font-size: 0.625em;
					}
					@media (min-width: 1200px) {
						font-size: 0.8125em;
					}
					margin: 0.9375em 0 0 0;
					-webkit-transition: all 2.0s ease-out;
					-moz-transition: all 2.0s ease-out;
					-ms-transition: all 2.0s ease-out;
					-o-transition: all 2.0s ease-out;
					transition: all 2.0s ease-out;
				}
				.fa, .fac {
					// font-size: 4.0rem;
					font-size: calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					font-size: -webkit-calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					font-size: -moz-calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					@media (max-width: 575.9px) {
						font-size: 2.0em;
					}
					@media (min-width: 1200px) {
						font-size: 4.0em;
					}
				}
			}
		}
	}
}

.swiper-button-next, .swiper-button-prev {
	&::after {
		display: inline-block;
		font-family: $font-icons2;
		font-size: 2.625em;
		font-weight: normal;
		font-style: normal;
		font-variant: normal;
		line-height: 1;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		color: $white;
	}
	&:hover {
		&::after {
			color: $brandcolor2;
		}
	}
	&:focus-visible {
		outline: none;
	}
}
.swiper-button-next {
	&::after {
		content: "\f000";
	}
}
.swiper-button-prev {
	&::after {
		content: "\f001";
	}
}


.swiper-pagination {
	.swiper-pagination-bullet {
		width: 0.625em;
		height: 0.625em;
		border-radius: 50%;
		background: none;
		border: 0.125em solid $white;
		opacity: 1.0;
		margin: 0 0.1em !important;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		// -webkit-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -moz-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -ms-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -o-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// transition: background-color 0.5s ease-out, border 0.5s ease-out;
		&:hover {
			background-color: $white;
			border: 0.3125em solid $white;
		}
	}
	.swiper-pagination-bullet-active {
		background-color: $brandcolor2;
		border: 0.125em solid $brandcolor2;
	}
}

