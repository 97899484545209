// Blocks General
.content-blocks-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-snap-type: y proximity;
	-webkit-overflow-scrolling: touch;
}

.block-outer-container {
	margin: 0 !important;
	padding: 0 !important;
}

.block-inner-container {
	padding: 0 !important;
}

.block-scroll-snap-center {
	scroll-snap-align: center;
	scroll-snap-stop: always;
}

.block-outer-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.block-inner-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
	.row {
		width: 100%;
	}
}

.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.block-button-group-container {
	padding-top: 1.25rem;
}

.block-background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.block-background-image-fixed {
	background-attachment: fixed !important;
	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll !important;
	}
}

.block-background-image-cover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.block-background-video {
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	overflow: hidden;
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem 0;
}




// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position:relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}
.img-background-fill {
	display: flex;
	justify-content: stretch;
	align-items: flex-end;
	align-self: stretch;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	figure {
		display: flex;
		justify-content: stretch;
		align-items: flex-end;
		visibility: visible;
		width: 100%;
		height: 100%;
		img, a img {
			visibility: hidden;
			width: 100%;
		}
		figcaption {
			visibility: visible;
			width: 100%;
		}
	}
}
.block-single-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

//Text One Column
.text-one-column-body-copy {
	// display: flex;
	// justify-content: center;
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}

	h4.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.large-text-callout-subheadline-text {
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.large-text-callout-body-copy {
		p {
			font-size: clamp(1.75em, -webkit-calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			font-size: clamp(1.75em, -moz-calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			font-size: clamp(1.75em, calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			line-height: 1.2em;
		}
	}
}




//Pull Quote
.pull-quote-container {
	h2.pull-quote-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.pull-quote-headline-text {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-headline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h4.pull-quote-subheadline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-subheadline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.pull-quote-subheadline-text {
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.pull-quote-body-copy {
		p {
			font-size: 2.0em;
			line-height: 1.25em;
		}
	}
	cite.pull-quote-citation {
		display: block;
		color: $gray-dark3;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 700;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}




// Text-With-Image Block
.block-text-image-body-column {
	padding: 0 2.5em;
	@media (max-width: 991.9px) {
		padding: 0;
	}
	.block-text-image-headline-container {
		padding-bottom: 2.5em;
	}
}
.block-text-with-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}




// Text-With-Gallery Block
.block-container-text-with-gallery {
	.block-text-with-gallery-body-column {
	}
	.block-text-with-gallery-gallery-column {
		overflow: hidden;
		.swiper-slide {
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
	}
}




//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $gray-mid3;
}





//Image Callout Cards
.block-container-image-callout-cards {
	.card {
		border: none;
		border-radius: 0;
		.card-body {
			a {
				// color: $brandcolor1;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light;
					text-decoration: none;
					.image-callout-headline {
						color: $brandcolor1-light;
						text-decoration: none;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
					.image-callout-headline {
						color: $brandcolor1-dark;
						text-decoration: none;
					}
				}
			}
			p {
				font-size: 1.0em;
				line-height: 1.2em;
			}
		}
	}
}





//Image Icon Row
.image-icon-container {
	padding: 1.25em;
}
.image-icon-headline {
	margin: 1.25em 0 0 0;
	color: $gray-mid3;
}
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-text p {
	color: $gray-dark2;
	line-height: 1.2em;
	margin-top: 1.25em;
}
.image-icon-card {
	height: 100%;
	margin: 0;
	padding: 0 0 2.5em 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.image-icon-card-img-top {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		.image-icon-image {
			margin: 0;
			padding: 0;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.image-icon-card-body {
		width: 100%;
		margin: 0;
		padding: 0;
		align-self: flex-end;
		flex-grow: 0;
	}
	.image-icon-card-footer {
		margin: 0;
		padding: 0;
		align-self: flex-end;
	}
}






//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}





//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.625em;
		.block-image-gallery-thumb {

		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item {
		padding: 0.625em;
	}
}



//Multi Image Galleries Block
.block-multi-image-galleries-gallery-all-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	.block-multi-image-galleries-slider {
		.image-slider-wrapper {
			.image-slide {
				.image-slide-image {
					opacity: 1.0;
					border: none;
					-webkit-filter: blur(0px) grayscale(0%) contrast(100%);
					-moz-filter: blur(0px) grayscale(0%) contrast(100%);
					-ms-filter: blur(0px) grayscale(0%) contrast(100%);
					-o-bfilter: blur(0px) grayscale(0%) contrast(100%);
					filter: blur(0px) grayscale(0%) contrast(100%);
				}
			}
		}
	}
}



//Background Image Callout Cards
.background-callout-card {
	width: 100%;
	border: none;
	border-radius: 0;
	.card-img {
		border-radius: 0;
	}
	.card-img-overlay {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 5vw;
		.background-callout-headline {
			color: $white;
			font-size: 2.25em;
			line-height: 0.875;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		.background-callout-body p {
			font-size: 1.0em;
			line-height: 1.2em;
		}
	}
}



//Accordion List Block
.block-container-accordion-list {
	.accordion-block {
		margin: 0;
		padding: 0;
		.card {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $gray-light1;
			-webkit-transition: border 1.0s ease-out;
			-moz-transition: border 1.0s ease-out;
			-ms-transition: border 1.0s ease-out;
			-o-transition: border 1.0s ease-out;
			transition: border 1.0s ease-out;
			.card-header {
				margin: 0;
				padding: 1.25em 0.5625em;
				padding-top: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				// border-bottom: 1px solid $gray-white2;
				-webkit-transition: border 1.0s ease-out;
				-moz-transition: border 1.0s ease-out;
				-ms-transition: border 1.0s ease-out;
				-o-transition: border 1.0s ease-out;
				transition: border 1.0s ease-out;
				.btn {
					margin: 0;
					padding: 0;
					font-size: clamp(1.3125rem, calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-size: clamp(1.3125rem, -webkit-calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-size: clamp(1.3125rem, -moz-calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-weight: 400;
					line-height: 1;
					color: $brandcolor1-light;
					text-transform: none;
					letter-spacing: 0;
					&:before {
						display: block;
						content: "";
						position: absolute;
						width: 0;
						height: 0.25rem;
						bottom: 0;
						left: 0;
						background-color: $brandcolor2;
						visibility: hidden;
						-webkit-transition: transform 0.5s ease-out;
						-moz-transition: transform 0.5s ease-out;
						-ms-transition: transform 0.5s ease-out;
						-o-transition: transform 0.5s ease-out;
						transition: .5s ease-out;
					}
					&::after  {
						display: none;
						content: "";
					}
					&:hover {
						color: $brandcolor1-light2;
						&:before {
							visibility: visible;
							width: 100%;
						}
					}
					&.active.focus, &.active:focus, &.focus, &.focus:active, &:active:focus, &:focus {
						outline: 0 !important;
						outline-offset: 0 !important;
						background-image: none !important;
						-webkit-box-shadow: none !important;
						-moz-box-shadow: none !important;
						box-shadow: none !important;
						&:before {
							visibility: visible;
							width: 100%;
						}
					}
					&[aria-expanded="true"] {
						.fac { transform: rotate(180deg); }
					}
					.fac {
						display: inline-block;
						position: absolute;
						top: 0.75em;
						right: 0.5625em;
						transition: transform 300ms ease-in-out;
						transform: rotate(0deg);
						color: $brandcolor2;
					}
				}
			}
			.card-body {
				font-size: clamp(1.0rem, calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				padding: 1.25em 0.5625em;
				padding-top: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				p {
					padding: 0;
					font-size: clamp(1.0rem, calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
					font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
					font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				}
				h3 {
					font-size: 1.875rem;
					color: $gray-dark1;
				}
			}
		}
	}
}



//Latest News Block
.block-container-latest-news {
	.block-latest-news-header-row {
		margin: 0;
		padding: 0 0 1.25rem 0;
		.block-latest-news-header-container {
			margin: 0;
			padding: 0;
			.block-latest-news-header {
				margin: 0;
				padding: 0 0 0 1.875rem;
				border-left: 0.625rem solid $brandcolor2;
				line-height: 1;
				@media (max-width: 575.9px) {
					border-left: none;
					padding: 0 0 0.625rem 0;
				}
			}
		}
		.block-latest-news-button-container {
			margin: 0;
			padding: 0;
			.btn-block-latest-news-more-news {
			}
		}
	}
	.block-latest-news-card-row {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 3.125rem;
		@media (max-width: 767.9px) {
			justify-content: center;
		}
		.block-latest-news-card-container {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			border-radius: 0;
			width: calc(33.33% - 2.083rem);
			@media (max-width: 767.9px) {
				width: 50%;
			}
			@media (max-width: 575.9px) {
				width: 100%;
			}
			.block-latest-news-teaser-card {
				&:hover {
					.card-img-overlay {
						background-color: rgba($black, 0.33);
						.card-img-overlay-text {
							.card-body {
								.news-article-date-container {
									.news-article-date {
										&::after {
											color: $brandcolor2;
										}
									}
								}
							}
						}
					}
				}
				.card-img-overlay {
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					-webkit-transition: background-color 1.0s ease-out;
					-moz-transition: background-color 1.0s ease-out;
					-ms-transition: background-color 1.0s ease-out;
					-o-transition: background-color 1.0s ease-out;
					transition: background-color 1.0s ease-out;
					.card-img-overlay-text {
						margin: 0;
						padding: 1.875rem;
						background: rgba($black, 0.66);
						background: -moz-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
						background: -webkit-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
						background: linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
						.card-body {
							.news-article-date-container {
								.news-article-date {
									color: $white;
									font-weight: 600;
									text-transform: uppercase;
									letter-spacing: 0.1em;
									&::after {
										content: "\f008";
										font-family: $font-icons2;
										display: inline-block;
										font-size: 2.625em;
										line-height: 1;
										font-weight: normal;
										font-style: normal;
										font-variant: normal;
										text-rendering: auto;
										-webkit-font-smoothing: antialiased;
										text-rendering: auto;
										position: absolute;
										bottom: -0.1875em;
										right: 0;
										color: $white;
										-webkit-transition: color 1.0s ease-out;
										-moz-transition: color 1.0s ease-out;
										-ms-transition: color 1.0s ease-out;
										-o-transition: color 1.0s ease-out;
										transition: color 1.0s ease-out;
									}
								}
							}
							.news-article-headline-container {
								.news-article-headline {
									color: $white;
									font-weight: 100;
									font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
									font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
									font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
								}
							}
						}
					}
				}
			}
		}
	}
}



//Project Callouts Block
.block-project-callouts-gallery-all-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 50% 50%;
	overflow: hidden;
	.image-slider-1 {
		grid-row: span 2;
		z-index: 1;
	}
	.image-slider-2 {
		z-index: 3;
	}
	.image-slider-3 {
		z-index: 2;
	}
	.image-slider {
		position: relative;
		pointer-events: initial;
		cursor: pointer;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		&:hover {
			.project-teaser-headline {
					.project-teaser-overlay {
					a h5::after {
						color: $brandcolor2;
					}
				}
			}
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			.project-teaser-headline {
					.project-teaser-overlay {
					a h5::after {
						color: $brandcolor2;
					}
				}
			}
		}
		.swiper-wrapper {
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			.swiper-slide {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				-webkit-transform-style: preserve-3d;
				-moz-transform-style: preserve-3d;
				transform-style: preserve-3d;
				img {
					margin: 0;
					padding: 0;
					width: 100% !important;
					height: 100% !important;
					opacity: 0;
				}
			}
		}
		.project-teaser-overlay {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0;
			padding: 0.625rem;
			z-index: 9999;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			text-align: center;
			background: rgba($black, 0.66);
			background: -moz-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
			background: -webkit-linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
			background: linear-gradient(0deg, rgba($black,0.66) 0%, rgba($black,0.33) 50%, rgba($black,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
			.project-teaser-headline {
				margin: 0;
				padding: 1.25rem;
				width: 100%;
				a {
					color: $black;
					text-decoration: none;
					&:hover {
						text-decoration: none;
						h5::after {
							color: $brandcolor2;
						}
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						text-decoration: none;
						h5::after {
							color: $brandcolor2-light;
						}
					}
					h5 {
						margin: 0;
						padding: 0;
						padding-right: 1.25rem;
						position: relative;
						color: $white;
						font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
						font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
						font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
						font-weight: 100;
						letter-spacing: -.025em;
						-webkit-transition: color 1.0s ease-out;
						-moz-transition: color 1.0s ease-out;
						-ms-transition: color 1.0s ease-out;
						-o-transition: color 1.0s ease-out;
						transition: color 1.0s ease-out;
						&::after {
							content: "\f008";
							font-family: $font-icons2;
							display: inline-block;
							font-size: 1.0rem;
							font-weight: normal;
							font-style: normal;
							font-variant: normal;
							text-rendering: auto;
							-webkit-font-smoothing: antialiased;
							text-rendering: auto;
							position: absolute;
							top: 0.125em;
							right: 1.25rem;
							color: $white;
						}
					}
				}
			}
		}
	}
}



//Testimonials Contact Block
.block-container-testimonials-contact {
	.block-testimonials-contact-testimonials-column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		background-color: $brandcolor1;
		color: $white;
		padding: 5.0rem 1.25rem;
		&::after {
			display: block;
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: -50vw;
			bottom: 0;
			width: 50vw;
			height: 100%;
			background-color: $brandcolor1;
		}
		.block-testimonials-contact-testimonials-container {
			margin: 0;
			padding: 0;
			.testimonial-slider-full-width {
				margin: 0;
				padding: 0;
				position: relative;
					&::before {
						display: block;
						content: '\f01e';
						position: absolute;
						z-index: 1;
						top: -0.625rem;
						left: -0.625rem;
						display: inline-block;
						color: rgba($white, 0.25);
						font-size: 2.5rem;
						font-family: $font-icons2;
						font-weight: 600;
						font-style: normal;
						line-height: 1;
						text-rendering: auto;
					}
				.testimonial-slider {
					margin: 0;
					padding: 0;
					.testimonial-slider-wrapper {
						margin: 0;
						padding: 0;
						.testimonial-slide {
							margin: 0;
							padding: 0;
							// max-height: calc(100vh - 20.0rem);
							position: relative;
							&::after {
								display: block;
								content: '\f01d';
								position: absolute;
								z-index: 1;
								bottom: 5.625rem;
								right: 0.625rem;
								display: inline-block;
								color: rgba($white, 0.25);
								font-size: 2.5rem;
								font-family: $font-icons2;
								font-weight: 600;
								font-style: normal;
								line-height: 1;
								text-rendering: auto;
							}
							.testimonial-container {
								width: calc(100% - 6.0rem);
								padding: 0 3.0rem 3.0rem 3.0rem;
								.testimonial-copy {
									max-height: calc(100vh - 25.0rem);
									overflow-x: hidden;
									overflow-y: scroll;
									display: -webkit-box;
									-webkit-line-clamp: 12;
									-webkit-box-orient: vertical;
									text-overflow: ellipsis;
						
									/* Hide scrollbar for IE, Edge and Firefox */
									-ms-overflow-style: none;  /* IE and Edge */
									scrollbar-width: none;  /* Firefox */
									/* Hide scrollbar for Chrome, Safari and Opera */
									&::-webkit-scrollbar {
										display: none;
									}
									
									p {
										font-size: clamp(1.25rem, calc(1.25rem + 0.75 * ((100vw - 36rem) / 39)), 2.0rem);
										font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.75 * ((100vw - 36em) / 39)), 2.0rem);
										font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.75 * ((100vw - 36em) / 39)), 2.0rem);
										font-weight: 100;
										text-rendering: auto;
									}
								}
								.testimonial-citation {
									color: $brandcolor2;
									.testimonial-citation-name {
										color: $brandcolor2;
										font-weight: 400;
										line-height: 1;
										margin-bottom: 0;
									}
									.testimonial-citation-city {
										color: $white;
										font-size: clamp(0.625rem, calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
										font-size: clamp(0.625rem, -webkit-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
										font-size: clamp(0.625rem, -moz-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
										font-weight: 600;
										text-transform: uppercase;
										letter-spacing: 0.1em;
										line-height: 1;
										margin-bottom: 0;
									}
								}
							}
						}
					}
					.block-testimonial-pagination {
					}
					.block-testimonial-button-prev, .block-testimonial-button-next {
						opacity: 0.25;
						-webkit-transition: opacity 1.0s ease-out;
						-moz-transition: opacity 1.0s ease-out;
						-ms-transition: opacity 1.0s ease-out;
						-o-transition: opacity 1.0s ease-out;
						transition: opacity 1.0s ease-out;
						&:hover {
							color: $brandcolor2;
							opacity: 1.0;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $white;
							opacity: 1.0;
						}
					}
					.block-testimonial-button-prev {
					}
					.block-testimonial-button-next {
					}
				}
			}
		}
		.block-testimonials-contact-affiliations-container {
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 991.9px) {
			}
			.footer-affiliations {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 2.5rem;
				.footer-affiliation {
					.footer-affiliation-logo {
						max-height: 6.5rem;
						max-width: 6.0rem;
					}
				}
			}
		}
	 }
	.block-testimonials-contact-contact-column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		padding: 5.0rem 0 5.0rem 5.0rem;
		@media (max-width: 991.9px) {
			padding: 5.0rem 3.5rem;
		}
		@media (max-width: 767.9px) {
			padding: 5.0rem 3.0rem;
		}
		@media (max-width: 575.9px) {
			padding: 5.0rem 2.5rem;
		}
		.block-testimonials-contact-contact-info-container {
			.block-testimonials-contact-body-container {
				padding: 0 0 1.875rem 0;
				p {
					font-size: clamp(1.5rem, calc(1.5rem + 0.5 * ((100vw - 36rem) / 39)), 2.0rem);
					font-size: clamp(1.5rem, -webkit-calc(1.5em + 0.5 * ((100vw - 36em) / 39)), 2.0rem);
					font-size: clamp(1.5rem, -moz-calc(1.5em + 0.5 * ((100vw - 36em) / 39)), 2.0rem);
					text-rendering: auto;
				}
			}
			.block-testimonials-contact-address-container {
				padding: 1.875rem 0;
				font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36rem) / 39)), 1.5rem);
				font-size: clamp(1.25rem, -webkit-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
				font-size: clamp(1.25rem, -moz-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
				.block-testimonials-contact-label {
					color: $brandcolor2;
					font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36rem) / 39)), 1.0rem);
					font-size: clamp(0.75rem, -webkit-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
					font-size: clamp(0.75rem, -moz-calc(0.75em + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
					font-weight: 600;
					line-height: 1;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					padding-top: 0.125rem;
				}
				a {
					color: $gray-dark3;
					text-decoration: none;
					&:hover {
						color: $brandcolor1-light;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1-light2;
						text-decoration: none;
					}
				}
				.block-testimonials-contact-address-mailing-row {
					padding-bottom: 1.875rem;
				}
				.block-testimonials-contact-address-phone-row {
				}
			}
			.block-testimonials-contact-button-container {
				padding: 1.875rem 0;
			}
		}
		.block-testimonials-contact-copyright-container {
			font-size: 0.75em;
			line-height: 1;
			text-align: center;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
			a {
				margin: 0;
				padding: 0.625rem 1.25rem;
				line-height: 1;
				border: 2px solid $brandcolor2;
				// border-bottom: 0.125rem solid rgba($white, 0);
				-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
				-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
				-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
				-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
				transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
				&:hover {
					background-color: $brandcolor2;
					color: $white;
					text-decoration: none;
					// border-bottom: 0.125rem solid $brandcolor2;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					background-color: $brandcolor2-dark;
					color: $white !important;
					text-decoration: none;
					border: 2px solid $brandcolor2-dark;
					// border-bottom: 0.125rem solid $brandcolor2-dark;
				}
			}
		}
	}
}



