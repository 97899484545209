.btn {
	font-family: $font-button1;
	// font-size: 1.125em;
	font-size: clamp(0.625em, -webkit-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	font-size: clamp(0.625em, -moz-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	font-size: clamp(0.625em, calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	border-radius: 0;
	padding: 1.0em 2.625em;
	margin: 0.25em 0.125em;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		// -webkit-box-shadow: 0 0 8px rgba($gray-white1, 0.8)!important;
		// -moz-box-shadow: 0 0 8px rgba($gray-white1, 0.8) !important;
		// box-shadow: 0 0 8px rgba($gray-white1, 0.8) !important;
	}
	&::after {
		content: '\f008';
		font-family: $font-icons2;
		display: inline-block;
		font-size: inherit;
		font-weight: normal;
		font-style: normal;
		line-height: 1;
		text-rendering: auto;
		margin: 0 0 0 0.625rem;
	}
}

.btn-sm {
	// font-size: 0.875em;
	font-size: clamp(0.4375em, calc(0.4375em + 0.25 * ((100vw - 36em) / 39)), 0.6875rem);
	padding: 0.875em 2.0em;
}

.btn-lg {
	// font-size: 1.5em;
	font-size: clamp(0.875em, calc(1.0em + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
	padding: 1.0em 3.125em;
}

.btn-primary {
	background-color: $brandcolor1-light;
	border: none;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
	}
}

.btn-secondary {
	background-color: $brandcolor2;
	border: none;
	&:hover {
		color: $white;
		background-color: $brandcolor2-light;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor2-dark;
	}
}

.btn-gray {
	background-color: $gray-mid2;
	color: $white;
	border: none;
	&:hover {
		color: $white;
		background-color: $gray-light3;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark1;
		border: none;
	}
}

.btn-dark {
	color: $white;
	background-color: $black;
	border: none;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: none;
	}
}

.btn-light {
	color: $brandcolor1;
	background-color: $white;
	border: none;
	&:hover {
		color: $brandcolor1-light2;
		background-color: $white;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		background-color: $gray-white3;
		border: none;
	}
}

.btn-outline-primary {
	background-color: none;
	color: $brandcolor1-light;
	border: 1px solid $brandcolor1-light;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 1px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 1px solid $brandcolor1;
	}
}

.btn-outline-secondary {
	background-color: none;
	color: $brandcolor2;
	border: 1px solid $brandcolor2;
	&:hover {
		color: $white;
		background-color: $brandcolor2-light;
		border: 1px solid $brandcolor2-light;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor2-dark;
		border: 1px solid $brandcolor2-dark;
	}
}

.btn-outline-gray {
	color: $gray-mid2;
	border: 1px solid $gray-mid2;
	&:hover {
		color: $white;
		background-color: $gray-light3;
		border: 1px solid $gray-light3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark1;
		border: 1px solid $gray-dark1;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border: 1px solid $black;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light;
		border: 1px solid $brandcolor1-light;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 1px solid $brandcolor1;
	}
}

.btn-outline-light {
	background-color: none;
	color: $white;
	border: 1px solid $white;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light;
		border: 1px solid $brandcolor1-light;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 1px solid $brandcolor1;
	}
}

.btn-link {
	color: $brandcolor1-light;
	text-decoration: none;
	border: none;
	&:hover {
		color: $brandcolor1-light2;
		text-decoration: underline;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
		text-decoration: underline;
		border: none;
	}
}
