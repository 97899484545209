.link-text-nav, .link-text-subnav {
	color: $black;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.75rem, calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	font-size: clamp(0.75rem, -webkit-calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	font-size: clamp(0.75rem, -moz-calc(0.75rem + 0.125 * ((100vw - 36em) / 39)), 0.875rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark !important;
		text-decoration: none;
	}
	.fac {
		color: $brandcolor1;
	}
}

.link-text-subnav {
	color: $gray-mid2 !important;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.625rem, calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.625rem, -webkit-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.625rem, -moz-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
	.fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 700;
	// font-size: 0.875em;
	font-size: clamp(0.5em, calc(0.5em + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

.nav-tabs {
	position: relative;
	z-index: 50;
}

header {
	nav {
		.navbar-collapse {
			.navbar-nav {
				@media (min-width: 991.9px) {
					li:hover>.dropdown-menu {
						display: block;
					}
				}
				.nav-link, .dropdown-item {
					font-family: $font-nav1;
					font-weight: 700;
					line-height: 1;
					letter-spacing: 0.1em;
					text-transform: uppercase;
					color: $white;
					-webkit-transition: all 0.5s ease-out;
					-moz-transition: all 0.5s ease-out;
					-ms-transition: all 0.5s ease-out;
					-o-transition: all 0.5s ease-out;
					transition: all .5s ease-out;
					&:hover {
						color: $white;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white !important;
						text-decoration: none;
					}
				}
				.nav-item {
					margin: 0;
					position: relative;
					.nav-link {
						position: relative;
						margin: 0 1.875em;
						padding: 0 0 0.625rem 0 !important;
						&:before {
							display: block;
							content: "";
							position: absolute;
							width: 0;
							height: 0.25rem;
							bottom: 0;
							left: 0;
							background-color: $brandcolor2;
							visibility: hidden;
							-webkit-transition: transform 0.5s ease-out;
							-moz-transition: transform 0.5s ease-out;
							-ms-transition: transform 0.5s ease-out;
							-o-transition: transform 0.5s ease-out;
							transition: .5s ease-out;
						}
						&.active {
							&:before {
								visibility: visible;
								width: 100%;
							}
						}
						&:hover {
							&:before {
								visibility: visible;
								width: 100%;
							}
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							&:before {
								visibility: visible;
								width: 100%;
							}
						}
					}
					.dropdown-toggle {
						margin-bottom: 0 !important;
						&:hover {
							.dropdown-menu {
								display: block;
								margin-top: 0; // remove the gap so it doesn't close
							}
						}
						&::after {
							content: "\f006";
							font-family: $font-icons2;
							display: inline-block;
							font-size: 0.5em;
							font-weight: normal;
							font-style: normal;
							line-height: 1;
							text-rendering: auto;
							border: none;
							transform: translateY(-0.125em);
							padding-left: 0.25em;
						}
					}
					.dropdown-menu {
						margin: 0;
						padding: 0.3125rem 0 0 0;
						border-radius: 0;
						border: 1px solid rgba($brandcolor1-light, 1.0);
						border-top: none;
						background-color: $brandcolor1;
						// -webkit-backdrop-filter: blur(4px);
						// -moz-backdrop-filter: blur(4px);
						// -ms-backdrop-filter: blur(4px);
						// -o-backdrop-filter: blur(4px);
						// backdrop-filter: blur(4px);
						box-shadow:
							0px 2.8px 2.2px rgba(0, 0, 0, 0.014),
							0px 6.7px 5.3px rgba(0, 0, 0, 0.02),
							0px 12.5px 10px rgba(0, 0, 0, 0.025),
							0px 22.3px 17.9px rgba(0, 0, 0, 0.03),
							0px 41.8px 33.4px rgba(0, 0, 0, 0.036),
							0px 100px 80px rgba(0, 0, 0, 0.05);
						.dropdown-item {
							margin: 0 !important;
							padding: 0.75em 1.25em 0.75em 1.3125em !important;
							color: $white;
							width: auto;
							border-bottom: 1px solid rgba($brandcolor1-light, 1.0);
							border-left: 0.5em solid rgba($brandcolor1, 0);
							&:last-child { 
								border-bottom: none;
							}
							&.active  {
								background: none;
								border-left: 0.5em solid $brandcolor2;
							}
							&:hover {
								background: none;
								border-left: 0.5em solid $brandcolor2;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background: none;
								border-left: 0.5em solid $brandcolor2-light;
							}
						}
					}
				}
			}
		}
	}
	.navbar-toggler {
		margin-left: auto;
		margin-right: auto;
		padding: 0.625rem 0;
		font-family: $font-nav1;
		font-size: 1.0rem;
		line-height: 1;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		color: $white;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (max-width: 991.9px) {
	.navbar-expand-lg {
		.navbar-collapse {
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw !important;
			margin: 0;
			padding: 0;
			background-color: $brandcolor1 !important;
			position: relative;
			z-index: 2;
			.navbar-nav {
				border: none;
				float: none;
				.nav-link, a.dropdown-item {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					text-align: center;
				}
				.nav-item {
					margin: 0;
					border-top: 1px solid $brandcolor1-light;
					.nav-link {
						padding: 1.125rem 1.875rem !important;
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active  {
							border-bottom: none;
						}
					}
					.dropdown-toggle {
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
					}
					.dropdown-menu {
						padding: 0;							
						a.dropdown-item {
							margin: 0 !important;
							padding: 0.9375rem 1.875rem !important;
							color: $white !important;
							background-color: $brandcolor1;
							border-bottom: 1px solid $brandcolor1-dark;
							&:last-child { 
								border-bottom: none;
							}
							&:hover {
								background-color: $gray-white1;
								border-bottom: initial;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background-color: $gray-white3;
								border-bottom: initial;
							}
						}
					}
				}
			}
		}
	}
}
