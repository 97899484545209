.entry-pages-home {
	main {
		position: relative;
	}

	@media (min-width: 991.9px) {
		// header {
		// 	position: absolute;
		// 	top: 0;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	width: 100vw;
		// 	min-height: 100vh;
		// 	@supports (min-height:100dvh) {
		// 		min-height: 100dvh;
		// 	}
		// 	z-index: 2;
		// 	padding-top: 0;
		// 	background: none;
		// 	pointer-events: none;
		// 	.header-top-container {
		// 		width: 80%;
		// 		max-width: 1800px;
		// 		@media (max-width: 1760px) { width: 81%; }
		// 		@media (max-width: 1720px) { width: 82%; }
		// 		@media (max-width: 1679px) { width: 83%; }
		// 		@media (max-width: 1638px) { width: 84%; }
		// 		@media (max-width: 1598px) { width: 85%; }
		// 		@media (max-width: 1558px) { width: 86%; }
		// 		@media (max-width: 1517px) { width: 87%; }
		// 		@media (max-width: 1477px) { width: 88%; }
		// 		@media (max-width: 1436px) { width: 89%; }
		// 		@media (max-width: 1396px) { width: 90%; }
		// 		@media (max-width: 1356px) { width: 91%; }
		// 		@media (max-width: 1315px) { width: 92%; }
		// 		@media (max-width: 1275px) { width: 93%; }
		// 		@media (max-width: 1234px) { width: 94%; }
		// 		@media (max-width: 1194px) { width: 95%; }
		// 		@media (max-width: 1153px) { width: 96%; }
		// 		@media (max-width: 1113px) { width: 97%; }
		// 		@media (max-width: 1073px) { width: 98%; }
		// 		@media (max-width: 1032px) { width: 99%; }
		// 		@media (max-width: 991.9px) { width: 100%; padding: 0; }
		// 		.header-logo-container {
		// 			background-color: rgba($brandcolor1, 0.8);
		// 			-webkit-backdrop-filter: saturate(180%) blur(8px);
		// 			-moz-backdrop-filter: saturate(180%) blur(8px);
		// 			-ms-backdrop-filter: saturate(180%) blur(8px);
		// 			-o-backdrop-filter: saturate(180%) blur(8px);
		// 			backdrop-filter: saturate(180%) blur(8px);
		// 			/* Fallback when backdrop-filter not supported */
		// 			@supports not (backdrop-filter: blur()) {
		// 				background-color: rgba($brandcolor1, 0.95);    
		// 			}
		// 			padding-left: 1.875rem;
		// 			padding-right: 1.875rem;
		// 			justify-content: center;
		// 		}
		// 	}
		// 	.header-nav-container {
		// 		nav {	
		// 		@media (min-width: 991.9px) {
		// 			display: none;
		// 			pointer-events: none;
		// 		}		
		// 			#header-menu {
		// 				.navbar-nav {
		// 					.dropdown {
		// 						.dropdown-menu {
		// 							background-color: rgba($white, 0.05) !important;
		// 							border: 1px solid rgba($white, 0.20);
		// 							-webkit-backdrop-filter: saturate(180%) blur(8px);
		// 							-moz-backdrop-filter: saturate(180%) blur(8px);
		// 							-ms-backdrop-filter: saturate(180%) blur(8px);
		// 							-o-backdrop-filter: saturate(180%) blur(8px);
		// 							backdrop-filter: saturate(180%) blur(8px);
		// 							/* Fallback when backdrop-filter not supported */
		// 							@supports not (backdrop-filter: blur()) {
		// 								background-color: rgba($white, 0.75);    
		// 							}
		// 							box-shadow: none;
		// 							.dropdown-item {
		// 								border-bottom: 1px solid rgba($white, 0.20);
		// 								&:last-child { 
		// 									border-bottom: none;
		// 								}
		// 							}
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	#home-banner-nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		@supports (height:100dvh) {
			height: 100dvh;
		}
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin: 0;
		padding: 0;
		pointer-events: none;
		@media (max-width: 991.9px) {
			display: none;
			pointer-events: none;
		}
		.home-banner-outer-nav-container {
			position: relative;
			margin: 0;
			padding: 0;
			width: 100vw;
			pointer-events: initial;
			&::after {
				display: block;
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100vw;
				height: 100%;
				z-index: -1;
				pointer-events: none;
				background-color: rgba($white, 0.33);
				-webkit-backdrop-filter: saturate(180%) blur(8px);
				-moz-backdrop-filter: saturate(180%) blur(8px);
				-ms-backdrop-filter: saturate(180%) blur(8px);
				-o-backdrop-filter: saturate(180%) blur(8px);
				backdrop-filter: saturate(180%) blur(8px);
				/* Fallback when backdrop-filter not supported */
				@supports not (backdrop-filter: blur()) {
					background-color: rgba($white, 0.66);    
				}
			}
			.home-banner-nav-container {
				padding: 0 1.875rem 0 1.875rem;
				display: flex;
				justify-content: stretch;
				align-items: center;
				nav {
					margin: 0;
					padding: 0;
					width: 100%;
					display: flex;
					justify-content: stretch;
					align-items: center;
					ul.navbar-nav {
						display: block;
						width: 100% !important;
						display: flex;
						justify-content: space-between;
						align-items: center;
						@media (min-width: 991.9px) {
							li:hover>.dropdown-menu {
								display: block;
							}
						}
						.nav-link, .dropdown-item {
							font-family: $font-nav1;
							font-weight: 700;
							line-height: 1;
							letter-spacing: 0.1em;
							text-transform: uppercase;
							color: $brandcolor1;
							&:hover {
								color: $brandcolor1-light;
								text-decoration: none;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								color: $brandcolor1-dark !important;
								text-decoration: none;
							}
						}
						.nav-item {
							margin: 0;
							position: relative;
							&::after {
								display: block;
								content: '';
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								width: 100%;
								height: 0;
								z-index: 1000;
								opacity: 0;
								display: inline-flex;
								justify-content: center;
								align-items: center;
								margin: 0;
								padding: 1.25rem;
								// -webkit-transition: all 0.5s ease-out;
								// -moz-transition: all 0.5s ease-out;
								// -ms-transition: all 0.5s ease-out;
								// -o-transition: all 0.5s ease-out;
								// transition: all 0.5s ease-out;
								background-color: rgba($white, 0.33);
								-webkit-backdrop-filter: saturate(180%) blur(8px);
								-moz-backdrop-filter: saturate(180%) blur(8px);
								-ms-backdrop-filter: saturate(180%) blur(8px);
								-o-backdrop-filter: saturate(180%) blur(8px);
								backdrop-filter: saturate(180%) blur(8px);
								/* Fallback when backdrop-filter not supported */
								@supports not (backdrop-filter: blur()) {
									background-color: rgba($white, 0.66);    
								}
							}
							&.dropup::after {
								background: none;
								-webkit-backdrop-filter: none;
								-moz-backdrop-filter: none;
								-ms-backdrop-filter: none;
								-o-backdrop-filter: none;
								backdrop-filter: none;
							}
							&.nav-item-spaces::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-rooms.png);
							}
							&.nav-item-projects::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-projects.png);
							}
							&.nav-item-process::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-process.png);
							}
							&.nav-item-about-us::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-about.png);
							}
							&.nav-item-sidelights::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-news.png);
							}
							&.nav-item-contact::after {
								content: url(/assets/images/content/home/icons/home-nav-icon-contact.png);
							}
							&:hover {
								&::after {
									top: -5rem;
									height: 5rem;
									opacity: 1;
								}
							}
							.nav-link {
								margin: 0 !important;
								padding: 1.25rem 0 2.5rem 0  !important;
								-webkit-transition: transform 0.5s ease-out;
								-moz-transition: transform 0.5s ease-out;
								-ms-transition: transform 0.5s ease-out;
								-o-transition: transform 0.5s ease-out;
								transition: .5s ease-out;
								&:before {
									display: block;
									content: "";
									position: absolute;
									width: 0;
									height: 0.625rem;
									bottom: 0;
									left: 0;
									background-color: $brandcolor2;
									visibility: hidden;
									-webkit-transition: transform 0.5s ease-out;
									-moz-transition: transform 0.5s ease-out;
									-ms-transition: transform 0.5s ease-out;
									-o-transition: transform 0.5s ease-out;
									transition: .5s ease-out;
								}
								&.active {
									&:before {
										visibility: visible;
										width: 100%;
									}
								}
								&:hover {
									&:before {
										visibility: visible;
										width: 100%;
									}
								}
								&:active, &:focus, &:not(:disabled):not(.disabled):focus {
									&:before {
										visibility: visible;
										width: 100%;
									}
								}
							}
							.dropdown-toggle {
								margin-top: 0 !important;
								&:hover {
									.dropdown-menu {
										display: block;
										margin-bottom: 0; // remove the gap so it doesn't close
									}
								}
								&::after {
									content: "\f007";
									font-family: $font-icons2;
									display: inline-block;
									font-size: 0.5em;
									font-weight: normal;
									font-style: normal;
									line-height: 1;
									text-rendering: auto;
									border: none;
									transform: translateY(-0.125em);
									padding-left: 0.25em;
								}
							}
							.dropdown-menu {
								background-color: rgba($white, 0.33);
								-webkit-backdrop-filter: saturate(180%) blur(8px);
								-moz-backdrop-filter: saturate(180%) blur(8px);
								-ms-backdrop-filter: saturate(180%) blur(8px);
								-o-backdrop-filter: saturate(180%) blur(8px);
								backdrop-filter: saturate(180%) blur(8px);
								/* Fallback when backdrop-filter not supported */
								@supports not (backdrop-filter: blur()) {
									background-color: rgba($white, 0.66);    
								}
								margin: 0;
								margin-left: -0.625rem;
								padding: 0 0 5.25rem 0;
								min-width: calc(100% + 1.25rem);
								border-radius: 0;
								border: none;
								box-shadow:
									0px -2.8px 2.2px rgba(0, 0, 0, 0.014),
									0px -6.7px 5.3px rgba(0, 0, 0, 0.02),
									0px -12.5px 10px rgba(0, 0, 0, 0.025),
									0px -22.3px 17.9px rgba(0, 0, 0, 0.03),
									0px -41.8px 33.4px rgba(0, 0, 0, 0.036),
									0px -100px 80px rgba(0, 0, 0, 0.05);
								.dropdown-item {
									margin: 0 !important;
									padding: 0.75em 1.25em !important;
									font-size: clamp(0.625rem, calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
									font-size: clamp(0.625rem, -webkit-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
									font-size: clamp(0.625rem, -moz-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
									width: auto;
									border-bottom: 1px solid rgba($gray-light1, 0.66);
									border-left: 0.5em solid rgba($black, 0);
									-webkit-transition: transform 0.5s ease-out;
									-moz-transition: transform 0.5s ease-out;
									-ms-transition: transform 0.5s ease-out;
									-o-transition: transform 0.5s ease-out;
									transition: .5s ease-out;
									&:last-child { 
										border-bottom: none;
									}
									&.active  {
										border-left: 0.5em solid $brandcolor2;
									}
									&:hover {
										border-left: 0.5em solid $brandcolor2;
										background: rgba($white, 0.33) !important;
									}
									&:active, &:focus, &:not(:disabled):not(.disabled):focus {
										border-left: 0.5em solid $brandcolor2-light;
										background: rgba($white, 0.33) !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.block-container-banners {
		.carousel-overlay {
			&::after {
				display: block;
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100vw;
				height: 10.0rem;
				// background: $white;
				background: -moz-linear-gradient(0deg, rgba($white,0.8) 0%, rgba($white,0) 100%);
				background: -webkit-linear-gradient(0deg, rgba($white,0.8) 0%, rgba($white,0) 100%);
				background: linear-gradient(0deg, rgba($white,0.8) 0%, rgba($white,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
				pointer-events: none;
			}
		}
		#banner-down-arrow a {
			bottom: 9.0rem;
		}
		.swiper-pagination {
			bottom: 6.0rem;
		}
	}

	#rooms {
		.block-multi-image-galleries-body-column {
			.block-headline-text {
				font-size: clamp(4.0rem, calc(4.0rem + 2 * ((100vw - 36em) / 39)), 6.0rem);
				font-size: clamp(4.0rem, -webkit-calc(4.0rem + 2 * ((100vw - 36em) / 39)), 6.0rem);
				font-size: clamp(4.0rem, -moz-calc(4.0rem + 2 * ((100vw - 36em) / 39)), 6.0rem);
				font-weight: 100;
				color: $gray-light1;
				text-transform: uppercase;
				letter-spacing: -0.1rem;
			}
			.block-subheadline-text {
				font-family: $font-heading1;
				font-weight: 700;
				line-height: 0.875;
				letter-spacing: -0.0125em;
				color: $black;
				text-transform: uppercase;
				overflow-wrap: break-word;
				hyphens: auto;
				-ms-hyphens: auto;
				-moz-hyphens: auto;
				b, strong {
					font-weight: 700;
				}
				font-size: calc(3.0em + 1 * ((100vw - 36em) / 39));
				font-size: -webkit-calc(3.0em + 1 * ((100vw - 36em) / 39));
				font-size: -moz-calc(3.0em + 1 * ((100vw - 36em) / 39));
				@media (max-width: 575.9px) {
					font-size: 3.0em;
				}
				@media (min-width: 1200px) {
					font-size: 4.0em;
				}
				border-left: 0.625rem solid $brandcolor2;
				margin: 0.625rem 0 2.5rem 0;
				padding: 0 0 0 1.875rem;
			}
			.text-one-column-body-copy {
				margin: 0;
				padding: 0;			
			}
			.block-button-group-container {
				margin-top: 1.875rem;
			}
		}
	}

	#projects {
		.block-project-callouts-body-column {
			.block-headline-text {
				border-left: 0.625rem solid $brandcolor2;
				margin: 0.625rem 0 2.5rem -2.5rem;
				padding: 0 0 0 1.875rem;
			}
			.block-subheadline-text {
			}
			.text-one-column-body-copy {
				margin: 0;
				padding: 0;			
			}
			.block-button-group-container {
				margin-top: 1.875rem;
			}
		}
	}

	#our-process {
		.block-text-with-gallery-body-column {
			.block-headline-text {
				border-left: 0.625rem solid $brandcolor2;
				margin: 0.625rem 0 2.5rem 0;
				padding: 0 0 0 1.875rem;
			}
			.block-subheadline-text {
			}
			.text-one-column-body-copy {
				margin: 0;
				padding: 0;			
			}
			.block-button-group-container {
				margin-top: 1.875rem;
			}
		}
	}

	#about-us {
		.block-text-image-body-column {
			.block-headline-text {
				border-left: 0.625rem solid $brandcolor2;
				margin: 0.625rem 0 2.5rem -2.5rem;
				padding: 0 0 0 1.875rem;
			}
			.block-subheadline-text {
			}
			.text-one-column-body-copy {
				margin: 0;
				padding: 0;			
			}
			.block-button-group-container {
				margin-top: 1.875rem;
			}
		}
	}
}
