.body, body { 
	font-family: $font-body1;
	font-weight: 300;
	font-style: normal;
	line-height: 1;
	color: $gray-dark1;
	text-rendering: auto;
}

.body {
	p {
		font-size: 1.125em;
		line-height: 1.25;
		font-weight: 300;
		a {
			color: $brandcolor1-light;
			text-decoration: underline;
			&:hover {
				color: $brandcolor1-light2;
				text-decoration: underline;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1;
				text-decoration: underline;
			}
		}
	}
	ul {
		@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
			list-style: none; /* Remove default bullets */
		}
		//list-style: none; /* Remove default bullets */
		margin: 1.25em 0;
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor2; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5em;   Also needed for space (tweak if needed) 
		// margin-left: -2.5em; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25em);
		font-size: 0.75em;
	}

	ul li {
		line-height: 1.125;
		margin: 0.625em 0 0.625em -1.5625em;
		padding-left: 0.5em;
		font-size: 1.125rem;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $brandcolor1 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}

	ul.unstyled {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child { 
				// padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b, strong {
				color: $black;
				font-weight: 700;
			}
		}
	}

	ul.list-2column {
		// margin-top: 0;
		// padding-top: 0.9375em;
		// border-top: 1px solid $brandcolor2;
		column-count: 2;
		column-gap: 2.5em;
		column-rule: 1px dotted $gray-white2;
		@include media-breakpoint-only(lg) {
			column-count: 1;
		}
		@include media-breakpoint-only(xs) {
			column-count: 1;
		}
	}
}


p.lead, .lead p {
	color: $black;
	font-family: $font-body1;
	font-weight: 100;
	line-height: 1.125;
	font-size: clamp(1.5em, -webkit-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	font-size: clamp(1.5em, -moz-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	font-size: clamp(1.5em, calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	b, strong {
		color: $brandcolor2;
		font-weight: 100;
	}
}

p.text-small, .text-small p, .text-small {
	font-size: 0.875rem;
	line-height: 1.25;
}

b, strong {
	color: $black;
	font-weight: 700;
}

a {
	color: $brandcolor1-light;
	text-decoration: none;
	&:hover {
		color: $brandcolor1-light2;
		text-decoration: underline;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
		text-decoration: underline;
	}
}

h1.display-1 {
	font-family: $font-heading1;
	font-weight: 700;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	color: $black;
	text-transform: uppercase;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 700;
	}
	font-size: clamp(2.25em, -webkit-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, -moz-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
}

h1.display-2 {
	font-family: $font-body1;
	font-weight: 700;
	font-style: normal;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	color: $brandcolor1;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 700;
	}
	font-size: clamp(1.75em, -webkit-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, -moz-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
}


h1.display-3 {
	font-family: $font-heading1;
	font-weight: 100;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	color: $black;
	text-transform: uppercase;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 100;
	}
	font-size: clamp(2.25em, -webkit-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, -moz-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
}

h1.display-4 {
	font-family: $font-body1;
	font-weight: 100;
	font-style: normal;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	color: $brandcolor1;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 100;
	}
	font-size: clamp(1.75em, -webkit-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, -moz-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	@media (max-width: 575.9px) {
		font-size: 2.0em;
	}
	@media (min-width: 1200px) {
		font-size: 3.0em;
	}
}

h1 {
	font-family: $font-heading1;
	font-weight: 700;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	color: $black;
	text-transform: uppercase;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 700;
	}
	@media (min-width: 991.9px) {
		font-size: clamp(2.5em, -webkit-calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
		font-size: clamp(2.5em, -moz-calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
		font-size: clamp(2.5em, calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
		// font-size: 2.5em;
	}
	@media (max-width: 991.9px) {
		font-size: clamp(2.25em, -webkit-calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
		font-size: clamp(2.25em, -moz-calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
		font-size: clamp(2.25em, calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	}
}

h2 {
	font-family: $font-heading1;
	font-weight: 700;
	font-style: normal;
	font-size: 3.0rem;
	line-height: 1;
	letter-spacing: -0.0125em;
	color: $black;
	text-transform: uppercase;
	overflow-wrap: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	b, strong {
		font-weight: 700;
	}
	font-size: clamp(2.0em, -webkit-calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
	font-size: clamp(2.0em, -moz-calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
	font-size: clamp(2.0em, calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
}

h3 {
	font-family: $font-heading1;
	font-weight: 400;
	line-height: 1;
	letter-spacing: -0.0125em;
	margin-bottom: 0.625em;
	color: $gray-mid3;
	b, strong {
		font-weight: 400;
	}
	font-size: clamp(1.3125rem, -webkit-calc(1.3125rem + 0.6875 * ((100vw - 36em) / 39)), 2.0em);
	font-size: clamp(1.3125rem, -moz-calc(1.3125rem + 0.6875 * ((100vw - 36em) / 39)), 2.0em);
	font-size: clamp(1.3125rem, calc(1.3125rem + 0.6875 * ((100vw - 36em) / 39)), 2.0em);
}

h4 {
	font-family: $font-heading1;
	font-weight: 700;
	line-height: 1;
	letter-spacing: -0.0125em;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	font-size: clamp(1.5rem, -webkit-calc(1.5rem + 0.375 * ((100vw - 36em) / 39)), 1.875rem);
	font-size: clamp(1.5rem, -moz-calc(1.5rem + 0.375 * ((100vw - 36em) / 39)), 1.875rem);
	font-size: clamp(1.5rem, calc(1.5rem + 0.375 * ((100vw - 36em) / 39)), 1.875rem);
}

h5 {
	font-family: $font-heading1;
	font-weight: 400;
	line-height: 1;
	letter-spacing: -0.0125em;
	color: $gray-dark1;
	b, strong {
		font-weight: 400;
	}
	font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
	font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
	font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
}

h6 {
	font-family: $font-heading1;
	font-weight: 700;
	line-height: 0.875;
	text-transform: uppercase;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
	font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
	font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
}

h1.display-1,
h1.display-2,
h1.display-3,
h1.display-4,
h1,
h2,
h3,
h4,
h5,
h6 {
	b, strong {
		color: $brandcolor2;
	}
}

.text-light, .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light ul li::before, .text-light ul li::before {
	color: $white !important;
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 0.625em;
}

h4.page-subheadline-text {
	margin-top: 0.625em;
}

h5.header-sm {
	font-size: 1.125em;
}

h6.header-xs {
	font-size: 0.875em;
	letter-spacing: 0.05em;
}

ul.detail-list {
	list-style-type: none;
	margin: 0 0 2.5em 0;
	padding: 0;
	color: $gray-mid2;
	border-top: 1px solid $gray-dark2;
	li {
		margin: 0;
		padding: 0.625em 0;
		font-size: 0.8em;
		line-height: 1.25;
		border-bottom: 1px solid $gray-white2;
		&:first-child { padding-top: 0.625em }
		&:last-child { border-bottom: none; }
		&::marker {
			content: "";
			display: none;
		}
		b {
			color: $black;
			font-weight: 600;
		}
		a {
			display: block;
			position: relative;
			font-weight: 600;
			color: $black;
			&::after {
				content: "\f008";
				font-family: $font-icons2;
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0.75em;
				color: $gray-mid2;
			}
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
				&::after {
					color: $brandcolor1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: none;
				&::after {
					color: $brandcolor1-dark;
				}
			}
		}
		p {
			margin: 0;
		}
	}
}

.columns2 {
	column-count: 2;
	column-gap: 2.5em;
	@include media-breakpoint-down(md) {
		column-count: 1;
		justify-self: center;
	}
	p {
		// break-inside: avoid-column;
	}
	ul {
		margin: 0 !important;
	}
}

.columns3 {
	column-count: 3;
	column-gap: 2.5em;
	@include media-breakpoint-down(lg) {
		column-count: 2;
	}
	@include media-breakpoint-down(md) {
		column-count: 1;
		justify-self: center;
	}
	p {
		// break-inside: avoid-column;
	}
	ul {
		margin: 0 !important;
	}
}

