header {
	display: block;
	position: relative;
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	// width: 100%;
	z-index: 2000;
	background-color: $brandcolor1;
	.header-top-container {
		position: relative;
		padding: 0 1.875rem;
		width: 100vw;
		.header-info-container {
			color: $white;
			font-size: 0.75em;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			padding: 1.25em 1.875em;
			gap: 3.75em;
			@media (max-width: 767.9px) {
				visibility: hidden;
				height: 81px
			}
			@media (max-width: 575.9px) {
			}
			b, strong {
				color: $brandcolor2;
			}
			a {
				color: $white;
				&:hover {
					color: $gray-white2;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					text-decoration: none;
				}
			}
			.info-phone {
				white-space: nowrap;
			}
		}
		.header-logo-container {
			display: block;
			// position: absolute;
			// top: 0;
			// left: 0;
			// width: 100%;
			// z-index: 3;
			margin: 0;
			padding: 1.25rem 0;
			// pointer-events: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 991.9px) {
				padding: 0;
				text-align: center;
				justify-content: center;
			}
			.header-logo {
				display: inline-block;
				width: 13.8125rem;
				height: 4.875rem;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0.9375em 0.625em;
				pointer-events: initial;
				@media (max-width: 1080px) {
					width: 225px;
					height: 110px;
					margin: 2.125em 0em 1.25em 0em;
				}
				@media (max-width: 991.9px) {
					width: 225px;
					height: 110px;
					margin: 1.25em 0em;
				}
				@media (max-width: 767.9px) {
					width: 198px;
					height: 96px;
					margin: 0.625em 0em;
					margin-left: auto;
					margin-right: auto;
				}
				@media (max-width: 575.9px) {
					// width: 120vw;
					// height: 25px;
				}
			}
		}
		.header-nav-container {
			margin: 0;
			padding: 1.625rem 0;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			@media (max-width: 991.9px) {
				padding: 0.625rem 0;
				text-align: center;
				justify-content: center;
			}
			.navbar {
				margin: 0;
				padding: 0;
			}
		}
	}
}
