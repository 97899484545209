.hosted-video-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

.video-thumb-container {
	position: relative;
	width: auto;
	&:hover {
		img {
			opacity: 0.5;
			filter: blur(4px);
			-webkit-filter: blur(4px);
			-moz-filter: blur(4px);
			-ms-filter: blur(4px);
			-o-filter: blur(4px);
		}
		.video-thumb-overlay {
			background-color: rgba($black, 0.5);
			// backdrop-filter: blur(4px);
			// -webkit-backdrop-filter: blur(4px);
			// -moz-backdrop-filter: blur(4px);
			// -ms-backdrop-filter: blur(4px);
			// -o-backdrop-filter: blur(4px);
			.video-thumb-button-container {
				background-color: rgba($black, 0.5);
				.video-thumb-button {
					color: $white;
					.fac, .fa {
						color: $white;
					}
				}
			}
		}
	}
	img {
		opacity: 1;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		background-color: rgba($gray-mid2, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button-container {
			display: block;
			margin: 0;
			padding: 0.625rem;
			background-color: rgba($brandcolor1, 0.90);
			-webkit-transition: all 2.0s ease-out;
			-moz-transition: all 2.0s ease-out;
			-ms-transition: all 2.0s ease-out;
			-o-transition: all 2.0s ease-out;
			transition: all 2.0s ease-out;
			.video-thumb-button {
				color: $brandcolor2;
				text-align: center;
				-webkit-transition: all 2.0s ease-out;
				-moz-transition: all 2.0s ease-out;
				-ms-transition: all 2.0s ease-out;
				-o-transition: all 2.0s ease-out;
				transition: all 2.0s ease-out;
				a {
					color: $brandcolor1-light;
					text-decoration: underline;
					&:hover {
						color: $brandcolor1-light2;
						text-decoration: underline;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1;
						text-decoration: underline;
					}
				}
				.link-text-nav {
					display: block;
					color: $white;
					// font-size: 0.8125em;
					font-size: calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					font-size: -webkit-calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					font-size: -moz-calc(0.625em + 0.1875 * ((100vw - 36em) / 39));
					@media (max-width: 575.9px) {
						font-size: 0.625em;
					}
					@media (min-width: 1200px) {
						font-size: 0.8125em;
					}
					margin: 0.9375em 0 0 0;
					-webkit-transition: all 2.0s ease-out;
					-moz-transition: all 2.0s ease-out;
					-ms-transition: all 2.0s ease-out;
					-o-transition: all 2.0s ease-out;
					transition: all 2.0s ease-out;
				}
				.fa, .fac {
					// font-size: 4.0rem;
					font-size: calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					font-size: -webkit-calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					font-size: -moz-calc(2.0em + 2.0 * ((100vw - 36em) / 39));
					@media (max-width: 575.9px) {
						font-size: 2.0em;
					}
					@media (min-width: 1200px) {
						font-size: 4.0em;
					}
				}
			}
		}
	}
}

.video-4x3-responsive {
	width: 100%;
	height: auto;
	aspect-ratio: 4 / 3;
}

.video-16x9-responsive {
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 6;
}
