// News Article Teaser Cards
.news-teaser-card {
	// width: calc(100% - 1.25em);
	margin: 0 0 2.5em 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.news-article-headline-container {
				.news-article-headline {
					color: $brandcolor1-light;
					text-decoration: none;
				}
			}
		}
		.card-footer {
			.card-news-bottom {
				a {
					color: $brandcolor1-light;
					text-decoration: none;
					.fa, .fac {
						color: $brandcolor2;
					}
				}
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.news-article-headline-container {
				.news-article-headline {
					color: $brandcolor1;
					text-decoration: none;
				}
			}
		}
		.card-footer {
			.card-news-bottom {
				a {
					color: $brandcolor1;
					text-decoration: none;
					.fa, .fac {
						color: $brandcolor2-dark;
					}
				}
			}
		}
	}
	.card-body {
		padding: 1.25em 0 0 0;
		.news-article-date-container {
			.news-article-date {
				color: $gray-dark2;
				font-size: clamp(0.625rem, calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				font-size: clamp(0.625rem, -webkit-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				font-size: clamp(0.625rem, -moz-calc(0.625rem + 0.125 * ((100vw - 36em) / 39)), 0.75rem);
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.1em;
			}
		}
		.news-article-headline-container {
			.news-article-headline {
				font-size: 1.25rem;
				color: $black;
				text-transform: none;
			}
		}
		.news-article-teaser-copy {
			color: $gray-mid2;
			font-size: 0.9375em;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 0;
	}
}

/* Main Articles Page */
.news-teasers-container {
	margin: 0;
	padding: 2.5em 1.875rem 5.0em 2.5em;
	.news-cards-row {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 3.125rem;
		@media (max-width: 767.9px) {
			justify-content: center;
		}
		.news-cards-container {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			border-radius: 0;
			min-width: calc(33.33% - 3.125rem);
			max-width: calc(33.33% - 3.125rem);
			@media (max-width: 767.9px) {
				min-width: calc(50% - 1.5625rem);
				max-width: calc(50% - 1.5625rem);
			}
			@media (max-width: 575.9px) {
				min-width: 100%;
				max-width: 100%;
			}
		}
	}
}

.news-categories-container {
	margin: 0;
	padding: 0 2.5rem 2.5rem 0;
	.news-categories-sticky {
		padding-top: 2.5rem;
	}
	.category-list-title {
		margin: 0 0 0.6125em 0;
		padding: 0 0 0.6125em 0;
		font-size: 0.9375rem;
		font-weight: 600;
		line-height: 1;
		letter-spacing: 0.05rem;
		color: $brandcolor2;
		text-transform: uppercase;
		border-bottom: 1px solid $black;
	}
	ul li {
		position: relative;
		display: block;
		font-family: $font-heading1;
		font-size: 0.75rem;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		&::after {
			content: "\f004";
			font-family: $font-icons2;
			display: inline-block;
			font-size: 0.625em;
			font-weight: normal;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-rendering: auto;
			position: absolute;
			top: 1.5em;
			right: 0;
			color: $gray-mid2;
		}
		&.active {
			a {
				color: $black;
			}
		}
		a {
			color: $black;
		}
		&:hover {
			a {
				text-decoration: none;
				color: $brandcolor1-light !important;
			}
			&::after {
				color: $brandcolor2;
			}
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			a {
				text-decoration: none;
				color: $brandcolor1;
			}
			&::after {
				color: $brandcolor2-dark;
			}
		}
	}
}

/* News Detail Pages */
.news-body-container {
	padding: 0 2.5em 2.5em 2.5em;
	.news-container {
		padding: 1.875em 2.5em 0 2.5em;
		.video-thumb-container {
			margin: 0 0 2.5em 0;
			.news-video-featured-thumbnail {
				width: 100%;
				height: auto;
			}
		}
		.news-article-featured-image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 2.5em 0;
			.news-article-featured-image {
				// width: 100%;
				// height: auto;
			}
		}
	}
}

.news-subheadline-text {
	font-size: 1.5em;
	b, strong {
		font-weight: 400;
		color: $gray-dark1;
	}
}

