/* People Cards */
.people-card-container {
	padding: 1.0em 0 5.0em 0;
}
.people-card {
	margin: 0 2.5em 5.0em 2.5em;
	border-radius: 0;
	width: 100%;
	&:hover {
		.card-people-title {
			color: $brandcolor1-light;
		}
	}
	.card-img-top {
		img {
			width: 100%;
			height: auto;
		}
	}
	.card-body {
		a {
			color: $brandcolor1-light;
			text-decoration: none;
			.card-people-title {
				color: $brandcolor1-light;
				text-decoration: none;
			}
			&:hover {
				color: $brandcolor1-light2;
				text-decoration: none;
				.card-people-title {
					color: $brandcolor1-light2;
					text-decoration: none;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1;
				text-decoration: none;
				.card-people-title {
					color: $brandcolor1;
					text-decoration: none;
				}
			}
		}
		.card-people-title {
			margin-bottom: 0.125em;
			font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36rem) / 39)), 1.5rem);
			font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36rem) / 39)), 1.5rem);
			font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36rem) / 39)), 1.5rem);
		}
		.card-people-title-position {
			margin-top: 0;
			margin-bottom: 0;
			font-family: $font-body1;
			font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
			font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
			font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
		}
	}
}

// People Cards on pages using the block
.people-cards-block {
	.image-callout-card {
		margin: 0 1.25em 2.5em 1.25em;
		@media (max-width: 991.9px) {
			margin: 0 0 2.5em 0;
		}
	}
	.image-callout-headline, .card-title {
		margin-bottom: 0;
	}
	.image-callout-subheadline {
		margin-top: 0.125em;
		margin-bottom: 0.25em;
		font-family: $font-body1;
		font-size: 1.25em;
	}
}


//Main People Detail Page
.people-detail-container {
	padding: 2.5em 2.5em 7.0em 2.5em;
	.people-detail-text {
		@media (max-width: 575.9px) {
			padding-top: 1.25em;
		}
		.people-detail-title-position {
			color: $brandcolor2;
		}
		.people-detail-bio {
			padding-top: 0.625em;
			padding-bottom: 1.25em;
			font-size: 1.125em;
			border-top: 1px solid $gray-light2;
		}
	}
}
